import { useEffect, useRef, useState } from "react";
import { beep } from "../helpers";
import useScreenOrientation from "../hooks/orientation"

const SCAN_RATE = 100;

export default function Scanner() {
  const canvas = useRef();
  const video = useRef(document.createElement("video"));
  const worker = useRef(document.createElement("video"));
  const oldTime = useRef(0);
  const orientation = useScreenOrientation();
  const started = useRef(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (started.current) {
      stop()
      start(orientation)
    }
  }, [orientation])
  const initializeAudio = () => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    if (window.AudioContext) {
      window.audioContext = new window.AudioContext();
    }
    const fixAudioContext = function (e) {
      if (window.audioContext) {
        // Create empty buffer
        const buffer = window.audioContext.createBuffer(1, 1, 22050);
        const source = window.audioContext.createBufferSource();
        source.buffer = buffer;
        // Connect to output (speakers)
        source.connect(window.audioContext.destination);
        // Play sound
        if (source.start) {
          source.start(0);
        } else if (source.play) {
          source.play(0);
        } else if (source.noteOn) {
          source.noteOn(0);
        }
      }
      // Remove events
      document.removeEventListener('touchstart', fixAudioContext);
      document.removeEventListener('touchend', fixAudioContext);
    };
    // iOS 6-8
    document.addEventListener('touchstart', fixAudioContext);
    // iOS 9
    document.addEventListener('touchend', fixAudioContext);
  }

  useEffect(() => {
    window.addEventListener("start-scanning", event => {
      start(orientation);
    })
    return () => {
      window.removeEventListener("start-scanning")
    }
  }, [])
  const startWorker = async () => {
    new Promise(resolve => {
      worker.current = new Worker("wasmWorker.js");
      worker.current.onmessage = async ev => {
        if (ev.data != null) {
          worker.current.terminate();
          const result = ev.data;
          let res = result.data;
          const rawCode = res;
          const event = new CustomEvent('scan-success', { detail: rawCode.toString() });
          window.dispatchEvent(event)
          stop();
          beep();
        }
      };
      resolve();
    })
  }
  const start = () => {
    setLoading(true)
    startWorker();
    initializeAudio();

    let resolution = { height: window.innerHeight ,width: window.innerWidth }
    if (orientation === "portrait") {
      resolution = { width: window.innerHeight ,height: window.innerWidth }
    }

    navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: "environment", ...resolution } }).then(stream => {
      video.current.srcObject = stream;
      video.current.setAttribute("playsinline", "true");
      video.current.play();
      requestAnimationFrame(tick);
      started.current = true
      setLoading(false)
    }).catch(err => {
      alert(err);
    });
  }

  const stop = () => {
    const event = new CustomEvent('scan-success', { detail: null});
    window.dispatchEvent(event)
    video.current.pause();
    if (video.current.srcObject) {
      video.current.srcObject.getVideoTracks().forEach(track => track.stop());
      video.current.srcObject = null;
    }
    if (worker.current) {
      worker.current.terminate();
    }
    const ctx = canvas.current?.getContext("2d");
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
    started.current = false;
  }
  
  function renderFrame(ctx) {
    // re-register callback
    // set internal canvas size to match HTML element size
    canvas.current.width = canvas.current.scrollWidth;
    canvas.current.height = canvas.current.scrollHeight;
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      // scale and horizontally center the camera image
      var videoSize = { width: video.current.videoWidth, height: video.current.videoHeight };
      var canvasSize = { width: canvas.current.width, height: canvas.current.height };
      var renderSize = calculateSize(videoSize, canvasSize);
      var xOffset = (canvasSize.width - renderSize.width) / 2;
      ctx.drawImage(video.current, xOffset, 0, renderSize.width, renderSize.height);
    }
  }
  function calculateSize(srcSize, dstSize) {
    var srcRatio = srcSize.width / srcSize.height;
    var dstRatio = dstSize.width / dstSize.height;
    if (dstRatio > srcRatio) {
      return {
        width:  dstSize.height * srcRatio,
        height: dstSize.height
      };
    } else {
      return {
        width:  dstSize.width,
        height: dstSize.width / srcRatio
      };
    }
  }
  const tick = (time) => {
    if (video.current.readyState === video.current.HAVE_ENOUGH_DATA) {
      const ctx = canvas.current?.getContext("2d");
      renderFrame(ctx);
      checkImageDataForBarcode(time, ctx);
    }
    requestAnimationFrame(tick);
  };

  const checkImageDataForBarcode = (time, ctx) => {
    if (time - oldTime.current > SCAN_RATE) {
      oldTime.current = time;
      let imageData = ctx.getImageData(0, 0, canvas.current.width, canvas.current.height);
      worker.current.postMessage({width: imageData.width, height: imageData.height});
      worker.current.postMessage(imageData, [imageData.data.buffer]);
    }
  }

  return (
    <div className="scanner-container">
      <canvas ref={canvas} id="canvas" className="scanCanvas"/>
      <div className="overlay">
        { loading && <div className="loader"></div> }
        { !loading && <button className="stop-scanning-cta" onClick={stop} >X</button> }
      </div>
    </div>
  );
}