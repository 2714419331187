import {useState, useEffect} from 'react'

const getOrientation = () => {
  const orientation = window.screen?.orientation?.type || window.screen?.mozOrientation || window.screen?.msOrientation;
  if (orientation) {
    return orientation === 'landscape-primary' || orientation === 'landscape-secondary' ? "landscape": "portrait"
  }
  return window.orientation !== 0 ? "landscape": "portrait"
}

const useScreenOrientation = () => {
  const [orientation, setOrientation] =
    useState(getOrientation())

  const updateOrientation = event => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        updateOrientation
      )
    }
  }, [])

  return orientation
}

export default useScreenOrientation